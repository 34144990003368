function Hotkeys() {
    return {
        a: {
            keyup: () => this.changePostQuizIndex(false)
        },
        d: {
            keyup: () => this.changePostQuizIndex(true)
        },
    }
}

export default Hotkeys
