<template>
    <div class="grid">
        <div
            class="body-nav-card clickable align-center"
            v-for="(anOption, indx) in items"
            :key="'bodynav' + indx"
            @click="selected(anOption)"
        >
            <h2>{{anOption.name}}</h2><i class="fas fa-2x marg-small" :class="anOption.icon"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Bodynav',
    props: [
        'items'
    ],
    methods: {
        selected(anOption) {
            this.$emit('set-active-item', anOption)
        },
    }
}
</script>

<style lang="sass" scoped>
.grid
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 1em
    grid-auto-rows: minmax(100px, auto)
.body-nav-card
    border: 2px solid #89949e
    border-radius: 6px
.body-nav-card:hover
    filter: brightness(1.2)
    *
        filter: brightness(1.4)
</style>
