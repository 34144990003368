<template>
    <div>
        <BodyNav
            :items="optionsToShow"
            v-on:set-active-item="active = $event"
        />
        <div class="scrollable-body">
            <BodyMain
                v-if="active"
                :active="active"
            />
        </div>
    </div>
</template>

<script>
import BodyNav from './BodyNav.vue'
import BodyMain from './BodyMain.vue'

export default {
    name: 'ModalOptionsSettings',
    props: ['options', 'itemsToShow'],
    components: {
        BodyNav,
        BodyMain,
    },
    data() {
        return {
            active: null
        }
    },
    computed: {
        optionsToShow() {
            const toShow = {}
            const objKeys = Object.keys(this.options)
            objKeys.forEach((key) => {
                if (this.itemsToShow.includes(key)) toShow[key] = this.options[key]
            })
            return toShow
        }
    },
    methods: {
    }
}
</script>

<style lang="sass" scoped>
.scrollable-body
    margin-top: 2rem
    padding-top: 2rem
    border-top: dotted 3px #89949e
    min-height: 15rem
</style>
