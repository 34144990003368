<template>
    <div class="modal-footer" style="align-items:stretch;">
        <span class="badge bg-purple clickable" @click="$emit('trash')">Trash <i class="fa fa-trash"></i></span>
        <span class="badge bg-purple clickable" @click="$emit('delete')">Delete <i class="fa fa-times"></i></span>
        <span v-if="hasExamples"
        class="badge clickable"
        :class="examplesClasses.outer"
        @click="$emit('toggle-examples')">
            {{examplesOnText}} <i class="fa" :class="examplesClasses.inner"></i>
        </span>
        <span v-if="source" class="badge bg-info">Source: {{cleanSource}}</span>
        <span v-if="status" :class="statusClasses">
            {{statusText}}
        </span>
    </div>
</template>

<script>
// TODO: plugin to Reviews.vue
export default {
    emits: [
        'toggle-examples',
        'trash', // item.id
        'delete', // item.id
    ],
    props: [
        'hasExamples',
        'showingExamples', // examplesToggled
        'source', // item.source
        'status', // item.status // item.status.definitionWrong/readingWrong
    ],
    computed: {
        statusText() {
            const { definitionWrong, readingWrong } = this.status
            if (definitionWrong || readingWrong) return 'Failed'
            return 'Passed'
        },
        statusClasses() {
            const { definitionWrong, readingWrong } = this.status
            if (definitionWrong || readingWrong) return 'badge bg-danger'
            return 'badge bg-success'
        },
        examplesOnText() {
            if (!this.showingExamples) return 'Examples OFF'
            return 'Examples ON'
        },
        examplesClasses() {
            if (!this.showingExamples) {
                return {
                    outer: 'bg-danger',
                    inner: 'fa-eye-slash',
                }
            }
            return {
                outer: 'bg-purple',
                inner: 'fa-eye',
            }
        },
        cleanSource() {
            return this.source.replace('book_', '').replace(/_/g, ' ')
        }
    }
}
</script>

<style>

</style>
