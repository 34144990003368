<template>
    <div v-cloak class="container-fluid" id="app" style="min-height:1200px;">
        <!-- session complete if theres no trash -->
        <!-- victory modal (100% accuracy only) -->
        <Toasts v-on:hide-toasts="1 == 1" :toasts="toasts" v-on:close-all="toasts = []"
            v-on:disable-help="[opts.tutorial = false, toasts = []]" />
        <PerfectResults :correct="ui.progress.correct" :incorrect="ui.progress.incorrect" :max="ui.progress.max"
            :showingModal="modals.victory" @leave="leave" />
        <!--options menu-->
        <ModalOptions :options="options" v-on:save-settings="saveSettings()" />
        <!--log details and post review modal-->
        <!-- card editor -->
        <ReviewsEditor :canNavigate="false" :showingModal="modals.editor" :mode="'srs'" :log="editModal.log"
            :item="editModal.item" :words="[words]" :kanji-info="kanjiInfo" :flags="flags" :pronunciation="pronunciation"
            :reference="reference" :ui="ui" :hasCorpusExamples="false"
            v-on:click-out="flags.post.postQueue ? '' : modals.editor = false">
            <template v-slot:header-top>
                <template v-if="flags.post.postQueue">
                    <span slot="hotkeys" v-if="flags.post.postQueue" v-hotkey.stop="KeymapModal"></span>
                    <HeaderSessionComplete v-if="flags.post.postQueue" :progress="flags.post.postIndex"
                        :progressMax="flags.post.postQueue.length" />
                </template>
                <HeaderTop v-if="editModal.item" :cardType="editModal.item.card_type" :kanjiInfo="kanjiInfo"
                    :word="editModal.item.word">
                    <LogStatus v-if="!flags.post.postQueue" :logAnswer="editModal.log.answer"
                        :logStatus="editModal.log.passed" />
                    <!-- navigation controls -->
                    <EditorNavigation v-if="flags.post.postQueue" :progress="flags.post.postIndex"
                        :progressMax="flags.post.postQueue.length - 1" v-on:prev="changePostQuizIndex(false)"
                        v-on:next="changePostQuizIndex(true)" v-on:exit="leave()" />
                </HeaderTop>
            </template>
            <template v-slot:controls-bottom>
                <ControlsBottom :source="editModal.item.source" v-on:trash="startTrash([editModal.item.id])"
                    v-on:delete="startDelete([editModal.item.id])" />
            </template>
        </ReviewsEditor>
        <div class="w-100 hide-navigation d-flex" v-tippy content="Show/hide navigation" @click="toggleWebNavigation()"
            style="height:3rem;">
            <i class="fas fa-2x" :class="flags.webNavigation ? 'fa-eye' : 'fa-eye-slash'"></i>
        </div>
        <!-- app -->
        <Spinner v-if="!flags.loaded" :badge="'bg-success'" :message="'Loading'" />
        <div v-show="flags.loaded" class="row">
            <div id="main" class="col-12">
                <!--main-->
                <div class="row">
                    <!-- review progress -->
                    <ReviewNav class="col-12 book-card" v-bind="flags" :ui="ui" :options="options" :len="words.length || 0"
                        :active="active" v-on:start-timer="startTimer($event)" v-on:stop-timer="stopTimer($event)"
                        v-on:start-wrap="startWrapup(words, garbage)" v-on:stop-wrap="endWrapup(words, garbage)"
                        v-on:receive-hints="receiveHints($event.isLesson)" />
                    <!--main-->
                    <div class="col-12 container-relative">
                        <Logs v-if="!options.showLogs.val && logs.length > 0" class="d-flex w-100 pad-big marg-big"
                            :logs="logs" v-on:open-modal="setEditModal($event)" />
                        <div class="row">
                            <!-- word plus kanji tooltips -->
                            <Word class="srs-main book-card col-12 col-md-8 mx-auto pad-big marg-big" :active="active"
                                :kanjiInfo="kanjiInfo" />
                            <div class="w-100"></div>
                            <div class="col-12 col-md-10 book-card mx-auto pad-small marg-big">
                                <!-- input field -->
                                <AnswerFld :active="active" :flags="flags" :options="options" :reference="reference"
                                    :$chosenReadingWithPronunciation="$chosenReadingWithPronunciation"
                                    :$inactiveWordReadings="$inactiveWordReadings" :$splitReadings="$splitReadings"
                                    :levenshteinDistance="levenshteinDistance"
                                    v-on:next-question="[newQuestion(), backupStreak()]"
                                    v-on:open-modal="[newQuestion(), backupStreak(), setEditModal(logs.slice(-1)[0])]"
                                    v-on:undo-answer="[newQuestion(), undoAnswer(), backupStreak()]"
                                    v-on:mark-incorrect="[manuallyMarkIncorrect(), newQuestion(), backupStreak()]"
                                    v-on:add-synonym="[addSynonym($event.active, $event.answer), newQuestion(), undoAnswer(), backupStreak()]" />
                                <DefinitionsAndReadings class="w-75 mx-auto" style="min-height:3rem; margin-top: 1em"
                                    v-if="flags.question.status" :levenshteinDistance="levenshteinDistance" :active="active"
                                    :flags="flags" :$chosenReadingWithPronunciation="$chosenReadingWithPronunciation"
                                    :$activeReadings="$activeReadings" />
                            </div>
                            <Auxilliary class="col-12 col-md-10 mx-auto pad-big marg-big" :active="active" :flags="flags"
                                :examples="examples" :reference="reference" :options="options" />
                            <!--EOR-->
                        </div>
                    </div>
                    <!--EOR-->
                </div>
            </div>
            <!--end of side-->
            <!--hack to make sure theres no popping in with the japanese font-->
            <div v-if="words.length > 0 && garbage.length == 0" style="opacity:0" lang="ja">
                {{ words.map(i => i.word).toString() }}</div>
            <!--EOR-->
        </div>
    </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { Modal as Bmodal } from 'bootstrap'
import {
    isKanji, isHiragana, isKatakana, bind, unbind, toHiragana
} from 'wanakana'
import { UseAPI, HandleRequestFail, SendUserAlert } from '@/assets/common'
import { SS } from '@/assets/constants'
import getSavedOptions from '@/assets/js/getSavedOptions'
import TOAST_CONFIG from '@/assets/js/toastsConfig/reviews'
import jitai from '@/assets/jitai'
import KeymapModal from './hotkeys/Modal'
import ReviewNav from './ReviewNav.vue'
import AnswerFld from './AnswerFld.vue'
import PerfectResults from './PerfectResults.vue'
import ModalOptions from './Options/ModalOptions.vue'
import Word from './Word.vue'
import Logs from './Logs.vue'
import DefinitionsAndReadings from './DefinitionsAndReadings.vue'
import Auxilliary from './Auxilliary/Auxilliary.vue'
import {
    ReviewsEditor,
    HeaderTop,
    HeaderSessionComplete,
    LogStatus,
    EditorNavigation,
    ControlsBottom,
} from './index'
import Toasts from '../Shared/Toasts.vue'
import Spinner from '../Spinner.vue'

import userOptions from './userOptions'
import { readCustomMarkup } from '@/features/render'
import {
    deleteWord,
    trashWord,
} from '@/features/api/index'

const wanakana = {
    isKanji, isHiragana, isKatakana, bind, unbind, toHiragana
}

window.jitai = jitai
console.log("Jitai", jitai)

export default {
    name: 'Reviews',
    components: {
        ReviewNav,
        AnswerFld,
        PerfectResults,
        ModalOptions,
        Word,
        Logs,
        ReviewsEditor,
        DefinitionsAndReadings,
        Toasts,
        Spinner,
        Auxilliary,
        // Editor
        HeaderTop,
        HeaderSessionComplete,
        LogStatus,
        EditorNavigation,
        ControlsBottom,
    },
    beforeDestroy() {
        if (jitai) {
            jitai.cleanup()
        }
    },
    methods: {
        modifySrsLevel(up = true, ids) {
            let apiLocation
            up ? apiLocation = "level-up" : apiLocation = "level-down"
            if (typeof ids !== 'object') ids = ids.toString().split(',')

            UseAPI(`/update/${apiLocation}`, { method: "POST", body: JSON.stringify({ items: ids }) })
                .then((dat) => {
                    console.log(dat)
                })
                .catch((dat) => {
                    console.log(dat)
                    console.log(JSON.stringify(dat))
                    HandleRequestFail(dat)
                    SendUserAlert('failed to update words... check console for logs', 'alert-danger')
                })
        },
        handleNewWords(dat) {
            if (dat.items.length <= 0) console.log('no items found', dat.options)
            const handleKanji = (i) => {
                const temp = i.entries[0]
                const newEntries = []
                i.readings.split(',').forEach((read) => {
                    // definition will be a reference copy which is fine because we want everything to be the same except reading
                    const obj = { ...temp }
                    obj.reading = read
                    newEntries.push(obj)
                })
                return newEntries
            }
            // add necessary meta
            dat.items.forEach((item) => {
                item.status = {
                    attempts: 0,
                    readingWrong: false,
                    definitionWrong: false,
                    chosenReading: false,
                    finished: false
                }
                if (item.card_type === 'k') item.entries = handleKanji(item)
                // could this break?
                // if(item.readings == '') item.status.chosenReading = item.entries[0].reading
                if (this.preMarkReading(item)) item.status.chosenReading = item.entries[0].reading
                item.entries.forEach((entry) => {
                    entry.subentries.forEach(
                        (subentry) => {
                            subentry.definition = subentry.definition.map((def) => def.trim().toLowerCase())
                        }
                    )
                    // entry.definition = entry.definition.map( (def)=>def.trim().toLowerCase())
                })
            })
            this.words = dat.items
            this.newQuestion()
            this.flags.loaded = true
            if (this.ui.progress.max === 0) this.initProgress()
            this.kanjiInfo = this.getKanjiInfo(dat)
            this.pronunciation = this.getPronunciationInfo(dat)
            this.reference = this.getReferenceInfo(dat)
            this.getExampleSentences(this.words)
            // eslint-disable-next-line prefer-arrow-callback
            this.$nextTick(function () {
                const answerNode = document.getElementById('answer')
                if (answerNode) {
                    console.log('shoudl focus answer node')
                    answerNode.focus()
                } else { console.log('cant find answerNode') }
            })
            return true
        },
        getPronunciationInfo(dat) {
            if (!dat.modules || !dat.modules.pronunciation) { console.log('failed to load pronunciation info'); return []; }
            return dat.modules.pronunciation.items
        },
        getReferenceInfo(dat) {
            if (!dat.modules || !dat.modules.reference) { console.log('failed to load reference words info'); return []; }
            return dat.modules.reference.items
        },
        async getExampleSentences(arr) {
            const vm = this
            let words = arr.map((i) => i.word).slice(0, 999)
            // this is such fucking lazy code dude...
            const ukWords = []
            arr.forEach((i) => {
                i.entries.forEach((o) => {
                    const usuallyKana = o.subentries.find((p) => 'pos' in p && p.pos.search(/uk/) !== -1)
                    if (i.readings.length === 0 || i.readings.includes(o.reading)) ukWords.push(o.reading)
                })
            })
            words = [...words, ...ukWords]
            const data = { words: words.toString(), limit: 1 }
            const x = UseAPI('/get/examples', {
                method: "POST",
                api: "corpus",
                body: JSON.stringify(data)
            })
                .then((dat) => {
                    console.log(dat)
                    if (dat && dat.items.length > 0) {
                        dat.items.forEach((i) => {
                            i.scoreChange = 1
                            i.alreadyScored = false
                        })
                        this.examples = dat.items
                        this.setExampleSentenceHtml(this.examples)
                        this.examples.sort((a, b) => a.paragraph.length - b.paragraph.length)
                    }
                    return dat
                })
                .catch((dat) => {
                    console.log('error getting example sentences')
                    HandleRequestFail(dat)
                    return false
                })
            return true
        },
        setExampleSentenceHtml(arr) {
            arr.forEach((i) => {
                const { paragraph, sentence, val } = i
                const delimiter = '!@#$'
                const replacementFunction = (v, sente) => {
                    const n = sente.replace(sente, `<span style="border-bottom:1px solid; padding-bottom:3px;">${sente}</span>`)
                    if (n.search(v) !== -1) {
                        return n.replace(v, `<span class="badge bg-purple" style="font-size:100%">${v}</span>`)
                    }
                    if (v.length > 1) {
                        const temp = val.substring(0, val.length - 1)
                        return n.replace(temp, `<span class="badge bg-purple" style="font-size:100%">${temp}</span>`)
                    }
                    return n
                }
                // second delimiter will leave a blank spot in the arr to fill in later
                let anotherArr = paragraph.replace(sentence, delimiter + delimiter)
                anotherArr = anotherArr.split(delimiter)
                // replace with stuff
                const sentencePosition = anotherArr.findIndex((ii) => !ii)
                for (let o = 0; o < anotherArr.length; o += 1) {
                    anotherArr[o] = `<span style="opacity:.75">${anotherArr[o]}</span>`
                }
                anotherArr[sentencePosition] = replacementFunction(val, sentence)

                const finalHtml = anotherArr.join('')
                i.html = finalHtml
            })
        },
        preMarkReading(item) {
            // returns true if answering reading question, returns false if answering r question is impossible
            // とも should not have a reading q, even if you somehow got something in the readings slot
            const entry = item.word
            if (item.readings === '') return true
            if (item.entries[0].reading === '') return true
            // this will occur since the query that connects card with the dict is a left join, meaning the dictionary entry will be NULL
            //  if (item.entries[0].entry == null) return true
            if ('entries' in item) {
                // check if there's an item with a different reading than the entry itself
                const found = item.entries.findIndex((i) => {
                    if (i.reading !== entry) {
                        return true
                    }
                    return false
                })
                if (found === -1) {
                    return true
                }
            }
            return false
        },
        getKanjiInfo(dat) {
            if (!dat.modules || !dat.modules.kanjiInfo) {
                console.log('failed to load kanji info')
                return []
            }
            return dat.modules.kanjiInfo.items
        },
        initProgress() {
            this.ui.progress.max = this.words.length;
        },
        newQuestion(item = false) {
            /* main */
            if ('word' in this.active) this.logs.push(this.makeLog(this.active, this.flags.question)) && console.log("Log updated", this.logs.slice(-1)[0])
            if (
                'status' in this.active
                && 'streaking' in this.options
                && this.options.streaking.val
            ) this.handleStreaking(this.flags.question)
            if ('status' in this.active && this.active.status.finished) {
                if (!this.flags.debug) {
                    this.active.status.readingWrong || this.active.status.definitionWrong
                        ? this.modifySrsLevel(false, this.active.id)
                        : this.modifySrsLevel(true, this.active.id)
                }
                this.removeQuestion(this.active, this.words)
            }
            if ((this.garbage.length > 0 && this.garbage.length % 10 === 0) || this.words.length === 0) this.massScoreExampleSentence(this.examples)
            if (this.words.length === 0) {
                this.reviewOver()
                return
            }
            /* get random item based on pool size, or override */
            if (!item) this.active = this.getNextQuestion()
            if (item) this.active = item
            this.resetStatus()
            this.setWanakana(this.flags.question)
            if (this.flags.debug) {
                const answerInput = document.getElementById('answer')
                this.flags.question.type === 'reading'
                    ? answerInput.val(this.active.readings.split(',')[0])
                    : answerInput.val(this.active.entries[0].subentries[0].definition[0])
            }
            // jitai font randomizer
            this.activateFontRandomizer(this.active)
            //  this.activeQuestionTimer() -> moved to answerFld
        },
        getNextQuestion() {
            const { words } = this;
            const { poolSize } = this.options;
            let item
            words.length < poolSize.val
                ? item = words[Math.floor(Math.random() * words.length)]
                : item = words[Math.floor(Math.random() * poolSize.val)]
            console.log('chose', item)
            return item
        },
        removeQuestion(active, words) {
            for (let i = 0; i < words.length; i += 1) {
                if (active === words[i]) {
                    this.garbage.push(active)
                    words.splice(i, 1)
                    break;
                }
            }
            return active
        },
        makeLog(item, qFlags) {
            // deconstruct necessary things and build a log
            const log = {
                card: item.word,
                id: item.id,
                answer: document.getElementById('answer').value,
                passed: qFlags.status,
                attemptNum: item.status.attempts,
                questionType: qFlags.type,
                chosenReading: item.status.chosenReading
            }
            /* override if question finished */
            if (qFlags.type === 'definition' && ['success', 'spelling'].includes(qFlags.status)) {
                /* then the question is finished */
                !item.status.definitionWrong && !item.status.readingWrong
                    ? log.passed = 'up'
                    : log.passed = 'down'
            }
            return log
        },
        handleStreaking(qFlags) {
            //  increment up/down
            try {
                if ('status' in qFlags) {
                    const { streak } = this.ui.gamification
                    if (qFlags.status === 'wrong') {
                        streak.current = 0
                    }
                    if (qFlags.status !== 'wrong') {
                        streak.current += 1
                        if (streak.current > streak.record) streak.record = streak.current
                    }
                }
            } catch (e) {
                console.log('error in handleStreaking', e, 'using param', qFlags)
            }
            return true
        },
        backupStreak() {
            this.ui.gamification.streak.beforeWrong = this.ui.gamification.streak.current
        },
        resetStatus() {
            document.getElementById('answer').value = ''
            this.flags.typable = true
            this.flags.question.status = false;
            !this.active.status.chosenReading ? this.flags.question.type = 'reading' : this.flags.question.type = 'definition'
            if (!wanakana.isKatakana(this.active.word)) this.flags.isKatakana = false
        },
        setWanakana() {
            const el = document.getElementById(this.answerFldNode)
            const wkAttributeName = `data-wanakana-id`
            if (this.flags.question.type === 'reading') {
                if (!el.attributes[wkAttributeName]) wanakana.bind(el)
            } else if (el.attributes[wkAttributeName]) wanakana.unbind(el)
        },
        startDelete(ids) {
            if (!Array.isArray(ids) || ids.length < 1) {
                SendUserAlert('No items selected', 'alert-warning')
                return false
            }
            deleteWord({
                arrayOfIds: ids,
            })
                .then((dat) => {
                    SendUserAlert('Deleted word', 'alert-success')
                    this.words = this.words.filter((i) => !ids.includes(i.id))
                    this.garbage = this.garbage.filter((i) => !ids.includes(i.id))
                    this.words.length === 0 ? this.reviewOver() : this.newQuestion()
                    this.modals.editor = false
                })
                .catch((dat) => {
                    SendUserAlert('there was an error deleting the words\ncheck console for error logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.warn('failed to delete; error log', JSON.stringify(dat))
                })
            return ids
        },
        startTrash(ids) {
            if (!Array.isArray(ids) || ids.length < 1) {
                SendUserAlert('No items selected', 'alert-warning')
                return false
            }
            trashWord({
                arrayOfIds: ids
            })
                .then((dat) => {
                    SendUserAlert('Trashed word', 'alert-success')
                    this.words = this.words.filter((i) => !ids.includes(i.id))
                    this.garbage = this.garbage.filter((i) => !ids.includes(i.id))
                    this.words.length === 0 ? this.reviewOver() : this.newQuestion()
                    this.modals.editor = false
                })
                .catch((dat) => {
                    SendUserAlert('there was an error deleting the words\ncheck console for error logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.warn('failed to delete; error log', JSON.stringify(dat))
                })

            return ids
        },
        addSynonym(item, val = prompt('Type a synonym')) {
            // KEEP THIS BECAUSE YOU NEED TO ABLE TO ADD SYNONYMS THROUGH HOTKEYS NOT JUST THE COMPONENT
            if (!val) return false
            const syn = item.synonyms.split(';')
            if (syn.find((i) => i === val)) {
                SendUserAlert('synonym already exists', 'alert-warning')
                return false
            }
            syn.length > 0 && syn[0] !== '' ? item.synonyms += (`;${val}`) : item.synonyms = val
            UseAPI('/update/modify-syn', { method: "POST", queryParameters: `val=${item.synonyms}&id=${item.id}` })
                .then((dat) => {
                    console.log('successfully modified')
                })
                .catch((dat) => {
                    SendUserAlert('error modifying data\ncheck console for logs', 'alert-warning')
                    HandleRequestFail(dat)
                    console.warn('error log', JSON.stringify(dat))
                });
            return true
        },
        undoAnswer() {
            const { logs, words } = this
            const viewingLog = logs[logs.length - 1]
            viewingLog.passed = 'undo'
            const searchId = viewingLog.id
            const item = words.find((i) => i.id === searchId)
            const filtered = logs.filter((i) => i.id === searchId)
            if (!filtered.some((i) => i.passed === 'wrong' && i.questionType === 'reading')) item.status.readingWrong = false
            if (!filtered.some((i) => i.passed === 'wrong' && i.questionType === 'definition')) item.status.definitionWrong = false
            item.status.finished = false
            //  undo streak reset
            this.ui.gamification.streak.current = this.ui.gamification.streak.beforeWrong
        },
        manuallyMarkIncorrect() {
            //  mark q type as wrong
            if (this.flags.question.type === 'reading') {
                this.active.status.readingWrong = true
                //  undo chosenReading setting (which would make you advance to the next q type for this item)
                this.active.status.chosenReading = false
            } else {
                this.active.status.definitionWrong = true
            }
            //  if its wrong it can't be finished
            this.active.status.finished = false
            this.flags.question.status = 'wrong'
        },
        startTimer(val) {
            // used with options.timerType.val = '2/3;xxx'
            const obj = val.split(';')
            if (obj.length !== 2) {
                SendUserAlert(`Error with start timer value. Invalid valid received. Check options. Value received was: ${val}`, 'alert-warning')
                return false
            }
            const timer = { style: obj[0], val: obj[1] }

            const tStyle2 = () => {
                SendUserAlert(`Time elapsed. Reviewing ending.`, 'alert-success')
                this.reviewOver()
            }

            console.log('received timer obj', timer)

            if (timer.style === "2") {
                SendUserAlert(`Review will end in ${timer.val} seconds.`, 'alert-info')
                this.flags.wrapup = setTimeout(tStyle2, timer.val * 1000)
                return true
            } if (timer.style === "3") {
                // when this.flags.wrapup is an object, the function newQuestion() will setup a timerId
                SendUserAlert(`Reviews will end after ${timer.val} seconds, starting next question.`, 'alert-info')
                this.flags.wrapup = {
                    style: 'perQuestionTimer',
                    time: timer.val * 1000,
                    timerId: null,
                }
                return true
            }
            return false
        },
        stopTimer(val) {
            const obj = val.split(';')
            if (obj.length !== 2) {
                SendUserAlert(`Error with start timer value. Invalid valid received. Check options. Value received was: ${val}`, 'alert-warning')
                return false
            }
            const timer = { style: obj[0], val: obj[1] }

            if (timer.style === 2) {
                if (clearTimeout(this.flags.wrapup)) SendUserAlert(`Timer cleared.`, 'alert-success')
                return true
            } if (timer.style === 3) {
                if (this.flags.wrapup.timerId) clearTimeout(this.flags.wrapup.timerId)
                this.flags.wrapup = false
                return true
            }
            return false
        },
        startWrapup(items, storage) {
            const { val } = this.options.timerType
            const wrapup = typeof val === 'string' && val.split(';').length === 2 ? val.split(';')[1] : 3
            // used with options.timerType.val = '1;xxx'
            // some array functions are mutating, and since we're not explicitly saying this.words = items.whatever, we have to run
            // mutating operations in order for items to not get a new object reference
            if (isNaN(wrapup)) {
                SendUserAlert(`Error with wrapup value. Expecting format X;X. Received : ${val}`)
            }
            if (!Array.isArray(storage) || !Array.isArray(items)) {
                SendUserAlert('invalid parameters received for startWrapup', 'alert-danger')
                console.log('items', typeof items, items, 'storage', typeof storage, storage)
                return
            }
            const itemsFiltered = items.slice(wrapup)
            itemsFiltered.forEach((filteredI) => {
                const y = items.findIndex((o) => o === filteredI)
                if (y !== -1) {
                    items.splice(y, 1)
                    storage.push(filteredI)
                }
            })

            SendUserAlert(`Shortening review to ${wrapup} or less items`, 'alert-info')
            this.flags.wrapup = true
        },
        endWrapup(items, storage) {
            if (!Array.isArray(storage) || !Array.isArray(items)) {
                SendUserAlert('invalid parameters received for endWrapup', 'alert-danger')
                console.log('items', typeof items, items, 'storage', typeof storage, storage)
                return
            }
            storage.forEach((i) => {
                if (!i.status.finished) {
                    items.push(i)
                }
            })
            items.forEach((i) => {
                const y = storage.findIndex((o) => i === o)
                if (y !== -1) storage.splice(y, 1)
            })
            this.flags.wrapup = false
        },
        activateFontRandomizer(item) {
            //  makes jitai switch the font if its on
            if (!jitai) return false
            if (!jitai.html.characters) return false
            if (!item) return false
            if ('randomizeFont' in this.options && this.options.randomizeFont.val === true) {
                jitai.setToRandomFont(item.word)
            }
            return true
        },
        reviewOver() {
            this.flags.typable = false
            document.getElementById('answer').value = `review complete...`
            this.flags.post.postQueue = this.handlePostReview()
            if (this.flags.post.postQueue && this.flags.post.postQueue.length > 0) {
                this.editModal = {
                    item: this.flags.post.postQueue[0]
                }
                this.modals.editor = true
            } else {
                if (this.ui.progress.total === 0) this.ui.progress.total += 1 // prevent division by 0
                this.modals.victory = true
            }
            return true
        },
        handlePostReview() {
            // go through logs, get ids (for items reviewed) and go through the garbage to find them. Put them in a new array
            const { undosInPostReview } = this.options
            console.log('undos in post', undosInPostReview)
            const isLogRelevant = (trackingUndos, val) => {
                if (trackingUndos) return (val === 'wrong' || val === 'undo')
                return val === 'wrong'
            }
            const logsOfInterest = this.logs.filter((i) => isLogRelevant(undosInPostReview.val, i.passed))
            const idSet = new Set(logsOfInterest.map((log) => log.id))
            // concat all the answers for each id together
            const final = []
            idSet.forEach((id) => {
                const found = this.garbage.find((garb) => garb.id === id)
                if (found) {
                    found.wrongAnswers = []
                    final.push(found)
                }
            })
            final.forEach((items) => {
                logsOfInterest.forEach((aLog) => {
                    if (aLog.id === items.id) items.wrongAnswers.push(aLog.answer)
                })
            })
            return final
        },
        changePostQuizIndex(forward) {
            const { post } = this.flags
            if (forward) {
                post.postIndex === post.postQueue.length - 1 ? post.postIndex = 0 : post.postIndex += 1
            } else {
                post.postIndex === 0 ? post.postIndex = post.postQueue.length - 1 : post.postIndex -= 1
            }
            //  this.item = post.postQueue[post.postIndex]
            this.editModal.item = post.postQueue[post.postIndex]
            this.flags.selectedReference = null
        },
        levenshteinDistance(a, b) {
            if (a.length === 0) return b.length;
            if (b.length === 0) return a.length;
            const matrix = [];
            // increment along the first column of each row
            let i;
            for (i = 0; i <= b.length; i += 1) {
                matrix[i] = [i];
            }
            // increment each column in the first row
            let j;
            for (j = 0; j <= a.length; j += 1) {
                matrix[0][j] = j;
            }
            // Fill in the rest of the matrix
            for (i = 1; i <= b.length; i += 1) {
                for (j = 1; j <= a.length; j += 1) {
                    if (b.charAt(i - 1) === a.charAt(j - 1)) {
                        matrix[i][j] = matrix[i - 1][j - 1];
                    } else {
                        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                            Math.min(matrix[i][j - 1] + 1, // insertion
                                matrix[i - 1][j] + 1)); // deletion
                    }
                }
            }
            return matrix[b.length][a.length];
        },
        massScoreExampleSentence(examples) {
            const garbage = this.garbage.map((i) => i.word)
            const arr = examples.filter((i) => !i.alreadyScored && garbage.includes(i.val))
            if (!arr) { return false; }
            const increase = arr.filter((i) => i.scoreChange === 1).map((i) => i.id)
            const decrease = arr.filter((i) => i.scoreChange !== 1).map((i) => i.id)
            if (!increase && !decrease) { return false; }
            if (increase.length === 0 && decrease.length === 0) { return false }

            arr.forEach((i) => { i.alreadyScored = true })
            const data = { increase, decrease }

            console.warn('Corpus scoring is currently disabled. Please reenable after confirming server stability.')
            return Promise.resolve(true)
        },
        leave() {
            // navigate away from page
            this.toggleWebNavigation() // put the fucking nav back
            const nav = document.querySelector('header')
            if (nav) nav.style.opacity = '1'
            this.$nextTick(() => {
                this.$router.push({ path: '/lessons' })
            })
        },
        toggleWebNavigation() {
            try {
                const nav = document.querySelector('header')
                if (this.flags === undefined || this.flags.webNavigation === undefined) {
                    console.warn('issue with this.flags.webNavigation', this.flags)
                    return
                }
                if (this.flags.webNavigation === true) {
                    this.flags.webNavigation = false
                    nav.style.opacity = 0
                    nav.style.pointerEvents = 'none'
                    return
                }
                if (this.flags.webNavigation === false) {
                    this.flags.webNavigation = true
                    nav.style.opacity = 1
                    nav.style.pointerEvents = 'auto'
                }
            } catch (e) {
                console.warn('error in toggleWebNavigation', e)
            }
        },
        setEditModal(log) {
            // reset selected reference word
            this.flags.selectedReference = null
            let found = false
            for (let i = 0; i < this.words.length; i += 1) {
                if (this.words[i].id === log.id) {
                    found = this.words[i]
                    break;
                }
            }
            /* check garbage if necessary */
            if (!found) {
                for (let i = 0; i < this.garbage.length; i += 1) {
                    if (this.garbage[i].id === log.id) {
                        found = this.garbage[i]
                        break;
                    }
                }
            }
            if (found) {
                this.editModal.item = found
                this.editModal.log = log
                this.modals.editor = true
                return true
            }
            SendUserAlert('Unable to find log', 'alert-warning')
            console.warn('Unable to find log', log)
            return false
        },
        receiveHints(isLesson) {
            const tutorialInit = () => {
                this.toasts.push(this.possibleToasts.quizzes)
                this.toasts.push(this.possibleToasts.answering)
                this.toasts.push(this.possibleToasts.logs)
                this.toasts.push(this.possibleToasts.shortcuts)
            }
            tutorialInit()
        },
        saveSettings() {
            const body = JSON.stringify({
                context: 'srs',
                val: JSON.stringify(this.options)
            })
            if (this.flags.optionsInitiated) {
                UseAPI('/create/user-settings', {
                    method: "PUT",
                    body
                })
                    .then((result) => {
                        SendUserAlert('Settings saved to server', 'alert-success')
                        //  remove cookie and refresh, so that it doesn't hold up future page loads
                        SS.remove(SS.USER_SETTINGS_COOKIE)
                        getSavedOptions()
                    })
                    .catch((result) => {
                        SendUserAlert('Error saving user settings to server', 'alert-danger')
                        console.log('Error saving user settings to server', result)
                    })
            }
        },
    },
    watch: {
        garbage: {
            handler(arr) {
                let correct = 0; let incorrect = 0
                this.garbage.forEach((i) => {
                    if (!i.status.finished) { return }
                    (i.status.readingWrong || i.status.definitionWrong) ? incorrect += 1 : correct += 1
                })
                this.ui.progress.correct = correct
                this.ui.progress.incorrect = incorrect
                this.ui.progress.max = this.garbage.filter((i) => i.status.finished).length + this.words.length
            },
            deep: true
        },
    },
    computed: {
        KeymapModal,
        // use this for complex interface display logic
        $splitReadings() {
            if (!('word' in this.active)) return false
            return this.active.readings.split(',')
        },
        $activeReadings() {
            if (!('word' in this.active)) return false
            return this.active.entries.map((i) => i.reading).filter((f) => this.$splitReadings.includes(f))
        },
        $inactiveWordReadings() {
            if (!('word' in this.active)) return false
            return this.active.entries.map((i) => i.reading).filter((i) => !this.$splitReadings.includes(i))
        },
        $splitReadings_modalKanji() {
            return this.editModal.item.readings.split(',')
        },
        $getOnReadings() {
            return this.$splitReadings_modalKanji.filter((i) => wanakana.isKatakana(i))
        },
        $getKunReadings() {
            return this.$splitReadings_modalKanji.filter((i) => !wanakana.isKatakana(i))
        },
        $getKanjiDefinition() {
            return this.editModal.item.entries[0].subentries[0].definition
        },
        $getPronunciations() {
            if (!('word' in this.active)) return false
            const { pronunciation, active } = this
            const returnedObj = {}
            if (pronunciation.length === 0) return false
            const relevantPronunciations = pronunciation.map((i) => i.word)
            if (relevantPronunciations && !relevantPronunciations.includes(active.word)) return false
            const readings = active.entries.map((i) => i.reading)
            /* check for cases where reading is in the list of pronunciations that match the word */
            readings.forEach((i) => {
                const found = pronunciation.find((k) => wanakana.toHiragana(k.reading) === i)
                if (!found) {
                    returnedObj[i] = `${i}`
                } else {
                    returnedObj[i] = found.pronunciation
                }
            })
            return returnedObj
        },
        $chosenReadingWithPronunciation() {
            if (!('word' in this.active)) return ""
            const { chosenReading = "" } = this.active.status;
            const { pronunciation } = this
            if (pronunciation.map((i) => i.word).includes(this.active.word)) {
                const x = pronunciation.find((k) => k.word === this.active.word && wanakana.toHiragana(k.reading) === chosenReading)
                if (x) { return `<pronunciation>${x.pronunciation}</pronunciation>` }
            }
            return chosenReading
        },
        $convertNoteToHtml() {
            if (!('notes' in this.editModal.item) || !(this.editModal.item.notes)) {
                return ""
            }
            return readCustomMarkup(this.editModal.item.notes)
        },
    },
    data() {
        return {
            modals: {
                victory: false,
                editor: false,
            },
            toasts: [],
            possibleToasts: null,
            answerFldNode: 'answer',
            active: {
            },
            words: {
            },
            kanjiInfo: [],
            pronunciation: [],
            reference: [],
            garbage: [],
            editModal: {
                item: {},
                log: {}
            },
            examples: [],
            logs: [],
            flags: {
                optionsInitiated: false, // has done initial options setup in created()
                debug: false,
                loaded: false,
                isLesson: false,
                webNavigation: true,
                typable: false,
                firstTryAgain: false,
                wrapup: false,
                question: {
                    type: 'reading',
                    useKatakana: false,
                    status: false,
                    leeway(len, base = 1.2, power = 2.4) { return len / (base * power) },
                    timer: {
                        getTime: () => { const d = new Date(); return d.getTime() }
                    },
                },
                post: {
                    postQueue: null,
                    postIndex: 0,
                },
                selectedReference: null,
                editingNotes: false,
                editingNoteText: "",
            },
            ui: {
                logs: {
                    hidden: false
                },
                progress: {
                    correct: 0,
                    incorrect: 0,
                    max: 0
                },
                gamification: {
                    streak: { current: 0, record: 0, beforeWrong: 0 /* backup val */ }
                }
            },
            options: userOptions
        }
    },
    mounted() {
        console.log('mounting reviews')
        this.toggleWebNavigation()
        jitai.init()
    },
    async created() {
        console.log('%cCreated Reviews', window.ConsoleStyles.createdComponent, this)
        const GetWords = () => {
            let ids = this.$route.query.ids
            if (!ids) ids = []
            try {
                // setup flags.isLesson
                if (typeof ids !== 'object') {
                    ids = ids.split(',')
                    if (this.flags === undefined || this.flags.isLesson === undefined) {
                        console.warn('issue setting this.flags.isLesson', this.flags)
                    } else {
                        this.flags.isLesson = true
                    }
                }
                /* // setup flags.debug
                if (this.flags !== undefined && this.flags.debug !== undefined) {
                    !window.localStorage.getItem('debug') ? false : JSON.parse(window.localStorage.getItem('debug'))
                    const localStorageVal = window.localStorage.getItem('debug')
                    this.flags.debug = JSON.parse(JSON.parse(localStorageVal))
                } */
            } catch (e) {
                console.warn('error in Reviews created', e)
            }
            return UseAPI('/get/reviews', {
                method: "POST",
                body: JSON.stringify({ items: ids })
            })
        }

        this.possibleToasts = TOAST_CONFIG
        const setupJitai = () => {
            if (!jitai) { return false }
            if (!('init' in jitai)) { return false }
            return true
            // need to call jitai.setToRandomFont(glyphs) later
        }
        const CONTEXT = 'srs'
        await getSavedOptions(CONTEXT)
            .then((resultingOpts) => {
                console.log('%cgetUserSettings', window.ConsoleStyles.routine, resultingOpts)
                //  use a specific key
                if (!resultingOpts) return
                Object.keys(resultingOpts).forEach((i) => {
                    this.options[i] = resultingOpts[i]
                })
            })
            .catch((err) => {
                console.log('getUserSettings', err)
                SendUserAlert(`Failed to fetch user settings: ${err}`, 'alert-warning')
            })
        GetWords()
            .then((res) => {
                this.handleNewWords(res)
            })
            .catch((res) => {
                console.warn('failed to retrieve words')
                HandleRequestFail(res)
            })
            .then(() => {
                this.flags.optionsInitiated = true
                // start review timer automatically
                if (this.options.timerType.val && ['2', '3'].includes(this.options.timerType.val[0])) {
                    SendUserAlert('Automatically turning on timer in 5 seconds...')
                    const timerSetup = () => {
                        this.startTimer(this.options.timerType.val)
                    }
                    setTimeout(timerSetup, 5 * 1000)
                }
            })
        setupJitai()
    },
}
</script>

<style lang="sass">
body
    &.dark
        #app
            .badge.bg-shadow
                box-shadow: 0px 1px 2px 0px #000000
            .srs-main
                color: rgba(255,255,255,.8)
                background: rgba(255,255,255, .25)
                background: linear-gradient(180deg, rgba(255,255,255,0.22) 25%, rgba(255,255,255,0.25) 100%)
#app
    .badge.bg-shadow
        box-shadow: 0px 1px 2px 0px #383838
    .srs-main
        /* appearance */
        display: flex
        flex-direction: column
        min-height: 12rem!important
        justify-content: center
    .hide-navigation
        justify-content: center
        align-items: center
        i
            opacity: 0
            transition: 600ms ease-out opacity
            padding: 0 2em
        &:hover i
            opacity: 1
    .srs-main
        /* font-scaling */
        font-size: 2.5rem
        @media (max-width: 768px)
            font-size: 1.75rem
    .srs-logs
        font-size: 1rem
    .corpus
        [lang="ja"]
            font-size: 1.2rem
</style>
