const userOptions = {
    poolSize: {
        val: 1,
        default: 1,
        required: "number",
        icon: "fa-swimming-pool",
        name: "Pool Size",
        description: `Determines the number of questions to be randomly asked from at once.
        When a question is finished a new one will take its place.
        A higher number increases randomness. A number of 1 will have meanings and readings back to back.`
    },
    timerType: {
        /* 1 WK wrap up 2 - total review timer 3 - per question timer
        style;value in seconds */
        val: "1;3",
        default: "1;3",
        required: "string",
        icon: 'fa-stopwatch',
        name: "Timer Style",
        description: "Change the behavior of the wrapup function.\n1 - Click stopwatch to answer X questions before review ends\n2 - Review will end after X seconds\n3 - Click stopwatch to toggle on: you have X seconds to answer\nTo modify this option use the following format on the prompt: style;value\nFor example: 1;5 or 3;600"
    },
    undosInPostReview: {
        val: true,
        default: true,
        required: "boolean",
        icon: 'fa-redo',
        name: 'Undos in Post-Review',
        description: "Show questions that were marked wrong but were undone afterward. Turn this off if you want only questions explicitly marked as wrong in the final post-review logs."
    },
    referenceReadingWarnings: {
        val: true,
        default: true,
        required: "boolean",
        icon: 'fa-check-double',
        name: "Related Words",
        description: "Receive a warning when you type a wrong reading that matches the reading of a reference word. Use this to prevent you from getting confused between related words."
    },
    lightningMode: {
        val: false,
        default: false,
        required: "boolean",
        icon: "fa-bolt",
        name: "Lightning Mode",
        description: "Auto-advance if the question was correct."
    },
    randomizeFont: {
        val: false,
        default: false,
        required: "boolean",
        icon: 'fa-font',
        name: "Randomize Font",
        description: "Randomizes font using the Jitai library. \nRequires fonts to be installed. Requires a page refresh.\nType jitai.help() into the browser console for info."
    },
    // why does this break if I get rid of streaking? Please.
    // Maybe has something to do with the actual vue html not checking if val exists
    streaking: {
        val: true,
        default: true,
        required: "boolean",
        icon: "fa-gamepad",
        name: "Streaking",
        description: "Toggles elements of gamification."
    },
    showLogs: {
        val: false,
        default: false,
        required: "boolean",
        icon: 'fa-eye',
        name: "Hide Logs",
        description: "Stops showing logs on the side of the screen."
    },
    showReferenceWordsBeforeInput: {
        val: true,
        default: true,
        required: "boolean",
        icon: 'fa-eye',
        name: "Show Ref Words Before Input",
        description: "Shows information about reference words before you answer.\nIf disabled, it will be hidden until after you input an answer."
    },
    showNotesBeforeInput: {
        val: false,
        default: false,
        required: "boolean",
        icon: 'fa-eye',
        name: "Show Notes Before Input",
        description: "Shows card notes before you answer.\nIf disabled, it will be hidden until after you input an answer."
    },
}

export default userOptions
