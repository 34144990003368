<template>
<div>
    <div class="row">
        <!--navigation-->
        <div class="nav-badges col-3 col-lg-2 px-0">
            <!--aux info-->
            <span v-if="isLesson"
                class="badge bg-shadow"
                :class="active.card_type == 'k' ? 'bg-red' : 'bg-purple'"
                v-tippy
                :content="text.quizMode"
            >
                quiz mode
            </span>
            <span v-if="!isLesson && options.streaking.val"
                class="badge bg-shadow"
                :class="gamificationBadge"
                style="transition:1000ms ease-out background-color"
            >
                streak <span class="font-size-plus-text">{{ui.gamification.streak.current}}</span>
            </span>
        </div>
        <div class="col-9 col-lg-8" style="align-self:center">
            <div class="w-100 pad-big">
                <!--progress-->
                <div class="progress w-100 container-relative">
                    <div class="progress-bar bg-danger"
                        role="progressbar"
                        :style="`width:${incorrectProg}%`"
                    >
                    </div>
                    <div class="progress-bar bg-success"
                        role="progressbar"
                        :style="`width:${correctProg}%`"
                    >
                    </div>
                    <div v-if="len > 0"
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        :class="potentialColor"
                        style="transition-property:all"
                        :style="`width:${potentialProg}%`">
                    </div>
                    <span class="pos-center">
                        {{numericProg}}
                    </span>
                </div>
            </div>
        </div>
        <!--wrap up-->
        <div class="nav-buttons col-9 offset-3 offset-lg-0 col-lg-2 px-0" style="align-self:center; text-align:center;">
            <span class="fa-stack">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-cog clickable fa-stack-1x"
                    @click="openOptions()"
                    v-tippy
                    content="Set options"
                ></i>
            </span>
            <span class="fa-stack">
                <i class="fas fa-square fa-stack-2x" :class="wrapup ? 'c-purple' : ''"></i>
                <i
                    v-if="timerStyleIsValid"
                    class="fas fa-stopwatch fa-stack-1x"
                    @click="wrapup ? stopTimer(options.timerType.val) : startTimer(options.timerType.val)"
                    v-tippy
                    content="Start review timer"
                ></i>
                <i v-else
                    class="fas fa-stopwatch fa-stack-1x"
                    @click="wrapup ? endWrapup() : startWrapup()"
                    v-tippy
                    content="Wrap up review"
                ></i>
            </span>
            <span class="fa-stack a-blink">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-question fa-stack-1x"
                    @click="$emit('receive-hints', {isLesson})"
                    v-tippy
                    :content="text.shortcutText"
                ></i>
            </span>
        </div>
    <!--EOR-->
    </div>
</div>
</template>

<script>
import { Modal as Bmodal } from 'bootstrap'

export default {
    name: 'ReviewNav',
    created() {
        console.log('%cCreated ReviewNav', window.ConsoleStyles.createdComponent, this)
    },
    mounted() {
        const setupModals = () => {
            this.modals.options = new Bmodal(document.getElementById('optionsModal'))
        }
        setupModals()
    },
    methods: {
        startTimer(val) {
            this.$emit('start-timer', val)
        },
        stopTimer(val) {
            this.$emit('stop-timer', val)
        },
        startWrapup() {
            this.$emit('start-wrap')
        },
        endWrapup() {
            this.$emit('stop-wrap')
        },
        openOptions() {
            //  this.$emit('open-options-modal')
            this.modals.options.show()
        }
    },
    computed: {
        numericProg() {
            const { ui } = this
            return `${(ui.progress.incorrect + ui.progress.correct)} / ${ui.progress.max}`
        },
        correctProg() {
            const { ui } = this
            return (ui.progress.correct / ui.progress.max) * 100
        },
        incorrectProg() {
            const { ui } = this
            return (ui.progress.incorrect / ui.progress.max) * 100
        },
        potentialProg() {
            const { ui, active, question } = this
            const val = 'status' in active && question.type === 'definition' ? 1 : 0
            return (val / ui.progress.max) * 100
        },
        potentialColor() {
            const { active } = this
            if ('status' in active && (active.status.readingWrong || active.status.definitionWrong)) return 'bg-danger'
            if ('status' in active && active.status.finished) return 'bg-success'
            return 'bg-warning'
        },
        gamificationBadge() {
            const { ui } = this
            const currentStreak = ui.gamification.streak.current || 0
            const gamificationClasses = []
            if (currentStreak === 0) {
                gamificationClasses.push('bg-jet')
            } else if (currentStreak < 5) {
                gamificationClasses.push('bg-secondary')
            } else if (currentStreak < 10) {
                gamificationClasses.push('bg-warning')
            } else if (currentStreak < 20) {
                gamificationClasses.push('bg-info')
            } else if (currentStreak < 30) {
                gamificationClasses.push('bg-success')
            } else {
                gamificationClasses.push('bg-purple')
            }
            if (currentStreak > 15 && currentStreak < 30) {
                gamificationClasses.push('font-size-plus-1')
            } else if (currentStreak > 29 && currentStreak < 45) {
                gamificationClasses.push('font-size-plus-2')
            } else if (currentStreak > 44 && currentStreak < 60) {
                gamificationClasses.push('font-size-plus-3')
            } else if (currentStreak > 59) {
                gamificationClasses.push('font-size-plus-4')
            }
            return gamificationClasses.join(' ')
        },
        timerStyleIsValid() {
            const { options } = this
            return options.timerType.val && ['2', '3'].includes(options.timerType.val[0]) && options.timerType.val.search(/;[0-9]/) !== -1
        }
    },
    data() {
        return {
            modals: {
                options: false
            },
            text: {
                quizMode: `You're being quized on the items you selected. If you get them right, they'll periodically come up for review. If you get them wrong, they'll be returned to the lessons menu and you can try again.`,
                shortcutText: `Click to receive a series of hints`
            }
        }
    },
    props: [
        'wrapup',
        'active',
        'isLesson',
        'question',
        'ui',
        'options',
        'len'
    ],
}
</script>

<style lang="sass" scoped>
.nav-badges
    display: flex
    justify-content: flex-end
    align-items: center
.nav-buttons
    font-size: 1.4rem
.font-size-plus-1 .font-size-plus-text
    transition: font-size ease .1s
    font-size: 110%
.font-size-plus-2 .font-size-plus-text
    transition: font-size ease .1s
    font-size: 120%
.font-size-plus-3 .font-size-plus-text
    transition: font-size ease .1s
    font-size: 130%
.font-size-plus-4 .font-size-plus-text
    transition: font-size ease .1s
    font-size: 140%
</style>
