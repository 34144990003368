<template>
<div class="modal-header d-block">
    <h2 class="pad-small">Session complete
        <i class="far fa-question-circle" v-tippy content="Based on your answer logs, here are some items you struggled with."></i>
    </h2>
    <div class="progress container-relative">
        <div class="progress-bar bg-info" role="progressbar"
            :style="`${calculateWidth}`"
        ></div>
        <span class="pos-center">{{calculateProgress}}</span>
    </div>
</div>
</template>

<script>
import {
    calculatePercent,
} from '@/features/render/index'

export default {
    props: [
        'progress',
        'progressMax',
    ],
    computed: {
        calculateProgress() {
            return `${this.progress + 1} / ${this.progressMax}`
        },
        calculateWidth() {
            const decimalToPercentWidth = (val) => {
                const asPercent = val * 100
                return `width: ${asPercent}%;`
            }
            return calculatePercent({
                current: this.progress + 1,
                max: this.progressMax,
            }, decimalToPercentWidth)
        }
    }
}
</script>

<style lang="scss" scoped>
.progress.container-relative {
    height: 1em;
    .pos-center {
        color: white!important;
    }
}
</style>
