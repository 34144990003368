<template>
    <span>
        <span v-if="progressMax > 0"
        class="fa-stack clickable" @click="$emit('prev')">
            <i class="fa-stack-2x fas fa-square c-purple"></i>
            <i class="fa-stack-1x fas" :class="prevClasses"></i>
        </span>
        <span class="fa-stack clickable" @click="$emit('exit')">
            <i class="fa-stack-2x fas fa-square"></i>
            <i class="fa-stack-1x fas fa-home"></i>
        </span>
        <span v-if="progressMax > 0"
        class="fa-stack clickable" @click="$emit('next')">
            <i class="fa-stack-2x fas fa-square c-purple"></i>
            <i class="fa-stack-1x fas" :class="nextClasses"></i>
        </span>
    </span>
</template>

<script>
export default {
    emits: [
        'exit',
        'prev',
        'next'
    ],
    props: [
        'progress',
        'progressMax',
    ],
    computed: {
        atEnd() {
            return this.progress === this.progressMax
        },
        atStart() {
            return this.progress === 0
        },
        prevClasses() {
            if (this.atStart) return 'fa-step-backward'
            return 'fa-angle-double-left'
        },
        nextClasses() {
            if (this.atEnd) return 'fa-step-forward'
            return 'fa-angle-double-right'
        },
    }
}
</script>

<style>

</style>
