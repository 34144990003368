<template>
<div>
    <h2 lang="ja" class="word-card" :class="cardColor">
        <span v-if="showVanilla">
            {{active.word}}
        </span>
        <span
            v-else
            v-for="(char, indx) in active.word"
            :key="indx+'c'"
            v-tippy
            :content="generateTooltipText(char)"
        >{{char}}</span>
    </h2>
</div>
</template>

<script>
export default {
    name: 'Word',
    props: [
        'active',
        'kanjiInfo',
    ],
    computed: {
        cardColor() {
            return this.active.card_type === 'k' ? 'c-red' : 'c-purple'
        },
        showVanilla() {
            const { active, kanjiInfo } = this
            const isKanji = 'card_type' in active && active.card_type === 'k'
            const kanjiInfoIsEmpty = kanjiInfo && 'length' in kanjiInfo && kanjiInfo.length === 0
            return kanjiInfoIsEmpty || isKanji
        }
    },
    methods: {
        generateTooltipText(char) {
            const { kanjiInfo } = this
            const found = kanjiInfo.find((i) => i.kanji === char)
            if (found) {
                console.log('found', found)
                const n = found.meaning
                        .split(';').slice(0, 3)
                        .filter((i) => i) //   remove empty elements
                //  Capitalize first letter
                n[0] = `${n[0].charAt(0).toUpperCase()}${n[0].slice(1)}`
                return n.toString()
                        .replace(/,/g, ', ')
            }
            return 'kanji not found'
        }
    }
}
</script>

<style lang="sass" scoped>
body
    h2
        *
            font-weight: normal
            text-shadow: 0px 1px 1px #2d0054
    &.dark
        h2
            *
                font-weight: normal
                text-shadow: 0px 1px 3px #2d0054
h2
    font-size: 2em
</style>
