<template>
    <div
        class="col-12 col-md-10 col-lg-7 mx-auto align-left marg-big"
    >
        <div class="marg-small w-100">
            <h2 style="font-size:1.2em;" class="d-inline">Notes</h2>
        </div>
        <div v-html="$convertNoteToHtml_activeItem"></div>
    </div>
</template>

<script>
import { readCustomMarkup } from '@/features/render'

export default {
    name: 'Notes',
    props: ['flags', 'active'],
    computed: {
        $convertNoteToHtml_activeItem() {
            if (!('notes' in this.active) || !(this.active.notes)) {
                return ""
            }
            return readCustomMarkup(this.active.notes)
        }
    }
}
</script>
