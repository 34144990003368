<template>
<div>
    <div style="padding: 1em; height:100%;">
        <h2>
            <span style="color:var(--purp)">{{item.word}}</span>
        </h2>
        <!-- show readings + entries based on "len" -->
        <div v-for="(entries, indx) in item.entries.slice(0, len)"
            :key="indx+'g'"
        >
            <span class="badge bg-danger">{{entries.reading}}</span>
            <div v-for="(subs, indx) in entries.subentries"
                :key="indx+'h'"
                class="ref-entry"
            >
                <span class="badge bg-info">
                    <i class="far fa-question-circle"
                        v-tippy
                        :content="subs.pos.toString()"
                    ></i>
                </span>
                <span class="badge bg-secondary"
                    v-for="(defs, indx) in subs.definition.slice(0,1)"
                    :key="indx+'i'"
                >
                    {{defs.length > 33 ? defs.substring(0,33)+'…' : defs}}
                </span>
                <span v-if="subs.definition.length > 1" class="badge bg-secondary"
                    v-tippy
                    :content="subs.definition.join(', ')"
                >
                    <i class="fa fa-ellipsis-h"></i>
                </span>
            </div>
        </div>
        <!-- expand button -->
        <div class="clickable"
            v-if="item.entries.length > len"
            @click="expand()"
        >
            <span class="badge bg-success"><i class="fas fa-plus"></i></span>
        </div>
        <div class="clickable"
            v-if="len > 1"
            @click="collapse()"
            v-tippy
            content="Show less"
        >
            <span class="badge bg-success"><i class="fas fa-minus"></i></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Reference',
    props: ['item'],
    data() {
        return {
            len: 1
        }
    },
    methods: {
        expand() {
            this.len = 10
        },
        collapse() {
            this.len = 1
        }
    }
}
</script>

<style lang="sass" scoped>
h2
    margin-bottom: 1em
    position: relative
h2::after
    content: ""
    border-bottom: 2px dotted grey
    position: absolute
    left: 0
    right: 0
    bottom: -.25em
    width: 100%
    max-width: 200px
    margin: auto
.ref-entry
    margin-right: .25em
.bg-success
    min-width: 5em
    padding-left: 0
    padding-right: 0
    i
        padding: 0
        margin: 0
</style>
