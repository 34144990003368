<template>
<div class="modal fade" id="optionsModal" tabindex="-1" role="dialog" aria-labelledby="optionsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2>Settings</h2>
                <span data-bs-dismiss="modal" class="fa-stack clickable">
                    <i class="fas fa-square fa-stack-2x"></i>
                    <i class="fas fa-times fa-stack-1x"></i>
                </span>
            </div>
            <ModalOptionsHeader
                :current="activeTab"
                v-on:switch-tab="activeTab = $event"
                v-on:reset-options="resetOptions()"
            />
            <div class="modal-body">
                <ModalOptionsSettings
                    :options="options"
                    :itemsToShow="optionsToShow"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ModalOptionsHeader from './ModalOptionsHeader.vue'
import ModalOptionsSettings from './ModalOptionsSettings/ModalOptionsSettings.vue'

export default {
    name: 'ModalOptions',
    props: [
        'options'
    ],
    components: {
        ModalOptionsHeader,
        ModalOptionsSettings
    },
    computed: {
        optionsToShow() {
            if (this.activeTab === 'UI') return this.uiOptions
            if (this.activeTab === 'behavior') return this.behaviorOptions
            return []
        }
    },
    watch: {
        options: {
            handler(arr) {
                this.$emit('save-settings')
            },
            deep: true
        },
    },
    methods: {
        resetOptions() {
            const keys = Object.keys(this.options)
            keys.forEach((aKey) => {
                const anOption = this.options[aKey]
                if ('default' in anOption) anOption.val = anOption.default
            })
        }
    },
    data() {
        return {
            activeTab: 'UI',
            uiOptions: [
                'showLogs',
                'streaking',
                'timerType',
                'showReferenceWordsBeforeInput',
                'showNotesBeforeInput'
            ],
            behaviorOptions: [
                'lightningMode',
                'poolSize',
                'randomizeFont',
                'referenceReadingWarnings',
                'undosInPostReview',
            ]
        }
    },
}
</script>

<style lang="sass" scoped>
#optionsModal
    .modal-body
        .scrollable-body
            max-height: 600px
            overflow-y: scroll
            .option-body
                border-bottom: 2px dashed #eee
                margin-bottom: 1.5em
</style>
