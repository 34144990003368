const jitai = {
    fonts: [
        // Default Windows fonts
        "Meiryo, メイリオ",
        "MS PGothic, ＭＳ Ｐゴシック, MS Gothic, ＭＳ ゴック",
        "MS PMincho, ＭＳ Ｐ明朝, MS Mincho, ＭＳ 明朝",
        "Yu Gothic, YuGothic",
        "Yu Mincho, YuMincho",
        // Default OS X fonts
        "Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3",
        "Hiragino Maru Gothic Pro, ヒラギノ丸ゴ Pro W3",
        "Hiragino Mincho Pro, ヒラギノ明朝 Pro W3",
        // Common Linux fonts
        "Takao Gothic, TakaoGothic",
        "Takao Mincho, TakaoMincho",
        "Sazanami Gothic",
        "Sazanami Mincho",
        "Kochi Gothic",
        "Kochi Mincho",
        "Dejima Mincho",
        "Ume Gothic",
        "Ume Mincho",
        // Other Japanese fonts people use
        // You might want to try some of these!
        "EPSON 行書体Ｍ",
        "EPSON 正楷書体Ｍ",
        "EPSON 教科書体Ｍ",
        "EPSON 太明朝体Ｂ",
        "EPSON 太行書体Ｂ",
        "EPSON 丸ゴシック体Ｍ",
        "cinecaption",
        "nagayama_kai",
        "A-OTF Shin Maru Go Pro",
        "Hosofuwafont",
        "ChihayaGothic",
        "'chifont+', chifont",
        "darts font",
        "santyoume-font",
        "FC-Flower",
        "ArmedBanana",
        "HakusyuKaisyoExtraBold_kk",
        "aoyagireisyosimo2, AoyagiKouzanFont2OTF",
        "aquafont",
        // Add your fonts here!
        "M PLUS 1p", "Kosugi",
        "Noto Sans JP",
        "Meiryo"
    ],

    existingFonts: [],

    CONSTANTS: {
        CHAR_SPAN: '.srs-main h2[lang=ja]',
        HOVER_FONT_SELECTOR: `.srs-main h2[lang=ja] span:hover`,
        DEFAULT_FONT: `"Noto Sans JP", "M PLUS 1p", Kosugi, Meiryo, メイリオ`,
    },

    html: {
        characters: null,
        stylesheet: null,
    },

    fontExists(fontName) {
        // Approach from kirupa.com/html5/detect_whether_font_is_installed.htm - thanks!
        // Will return false for the browser's default monospace font, sadly.
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const text = "wim-—l~ツ亻".repeat(100); // Characters with widths that often vary between fonts.
        context.font = "72px monospace";
        const defaultWidth = context.measureText(text).width;
        // Microsoft Edge raises an error when a context's font is set to a string
        // containing certain special characters... so that needs to be handled.
        try {
            context.font = `72px ${fontName}, monospace`;
        } catch (e) {
            return false;
        }
        const testWidth = context.measureText(text).width;
        return testWidth !== defaultWidth;
    },

    setToRandomFont(glyphs) {
        // The font is set as a randomly shuffled list of the existing fonts
        // in order to always show a random font, even if the first one chosen
        // doesn't have a certain glyph being attempted to be displayed.
        const randomlyOrdered = this.getShuffledFonts();

        // Some fonts don't contain certain radicals, for example, so it's best
        // to check that the font used can represent all the glyphs. The reason
        // the browser can't switch automatically is that some fonts report that
        // they have a glyph, when in fact they just show up blank.
        let currentFont;
        if (glyphs) {
            for (let i = 0; i < randomlyOrdered.length; i += 1) {
                const fontName = randomlyOrdered[i];
                if (this.canRepresentGlyphs(fontName, glyphs)) {
                    currentFont = fontName;
                    break;
                } else {
                    console.warn(`Font ${fontName} can't display ${glyphs}, moving to next`)
                }
            }
        } else {
            currentFont = randomlyOrdered.join(', ');
        }

        this.currentFont = currentFont;

        /* this.setHoverFont(this.defaultFont); */
        if (this.html.stylesheet) {
            const rule = ` ${this.CONSTANTS.CHAR_SPAN} { font-family: ${currentFont}!important}`

            const match = new RegExp(`${this.CONSTANTS.CHAR_SPAN} {(.*?)}`)
            this.html.stylesheet.textContent = rule + this.getHoverFont()
        }
    },

    canRepresentGlyphs(fontName, glyphs) {
        const canvas = document.createElement('canvas');
        canvas.width = 50;
        canvas.height = 50;
        const context = canvas.getContext("2d");
        context.textBaseline = 'top';
        const blank = document.createElement('canvas');
        blank.width = canvas.width;
        blank.height = canvas.height;
        const blankDataUrl = blank.toDataURL();
        context.font = `24px ${fontName}`;
        let result = true;
        for (let i = 0; i < glyphs.length; i += 1) {
            context.fillText(glyphs[i], 0, 0);
            if (canvas.toDataURL() === blankDataUrl) {
                result = false;
                break;
            }
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
        return result;
    },

    getHoverFont() {
        const rule = ` ${this.CONSTANTS.HOVER_FONT_SELECTOR} {font-family: ${this.CONSTANTS.DEFAULT_FONT} !important;}`
        return rule
    },

    getShuffledFonts() {
        // This shouldn't have to be part of the Jitai object,
        // but it uses Jitai's local copy of Math.random, so
        // this is pretty much the most reasonable way to do it.
        const f = this.existingFonts.slice();
        for (let i = f.length; i > 0;) {
            const otherIndex = Math.floor(this.random() * i);
            i -= 1;

            const temp = f[i];
            f[i] = f[otherIndex];
            f[otherIndex] = temp;
        }
        return f;
    },

    preCheck() {
        const failedFonts = []
        for (let i = 0; i < this.fonts.length; i += 1) {
            const fontName = this.fonts[i];
            if (this.fontExists(fontName)) {
                this.existingFonts.push(fontName);
            } else if (fontName === "Noto Sans JP") {
                //  force through if the default font (noto sans jp)
                this.existingFonts.push(fontName);
                break;
            } else {
                failedFonts.push(fontName)
            }
        }
        if (failedFonts.length > 0) console.warn('JITAI: the following fonts do not appear to exist on the system', failedFonts)
    },

    init() {
        // Reorder scripts seem to like overwriting Math.random(!?), so this
        // workaround is required for Jitai to work in conjunction with them.
        console.log('jitai initing', 'source @', 'https://gist.github.com/obskyr/9f3c77cf6bf663792c6e', 'list of fonts can be added to be accessing the jitai.fonts variable', this.fonts)

        this.preCheck()

        const iframe = document.createElement('iframe');
        iframe.className = 'jitai-workaround-for-reorder-script-compatibility';
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        this.random = iframe.contentWindow.Math.random;

        this.html.characters = document.querySelector(this.CONSTANTS.CHAR_SPAN)

        const styles = document.createElement("style")
        styles.type = 'text/css'
        styles.appendChild(document.createTextNode(''))
        document.head.appendChild(styles)
        this.html.stylesheet = styles
    },

    cleanup() {
        if (this.html.stylesheet) {
            this.html.stylesheet.remove()
        }
    },

    help() {
        console.log(`This shit is black magic. Anyway, AFAIK you need to have the fonts its using installed on your computer in order for them to work.
            This means that the default JP fonts for this website "M PLUS 1p", "Kosugi", "Noto Sans JP" need to be installed on your system for them to work normally.
            You can access the list of fonts being used by typing console.log(fonts) into the console. That's the list of fonts.
            You can type console.log(jitai.existingFonts) to see what jitai successfully identified as a working font on your PC.
            In order to load custom fonts in, clear out the existing font array with jitai.existingFonts = []
            Push your fonts into the font array with fonts.push(). I imagine that would be annoying to do every time. Maybe set up a userscript with tamper monkey?
            `)
    }
};

export default jitai
