<template>
    <div v-if="logStatus && logAnswer">
        <span class="badge" :class="logClasses">
            You answered {{logAnswer}} <i class="fas" :class="logClassesIcon"></i>
        </span>
    </div>
</template>

<script>
export default {
    props: [
        'logStatus', // log.passed
        'logAnswer', // log.asnwer
    ],
    computed: {
        logClasses() {
            const CORRECT = 'success'
            const INCORRECT = 'wrong'
            if (this.logStatus) {
                if (this.logStatus === CORRECT) return 'bg-success'
                if (this.logStatus === INCORRECT) return 'bg-danger'
            }
            return 'bg-warning'
        },
        logClassesIcon() {
            const CORRECT = 'success'
            const INCORRECT = 'wrong'
            if (this.logStatus) {
                if (this.logStatus === CORRECT) return 'fa-check'
                if (this.logStatus === INCORRECT) return 'fa-times'
            }
            return 'fa-times'
        },
    }
}
</script>

<style>

</style>
