<template>
    <div
        class="w-100 row marg-small option-body"
    >
        <div class="col-4 align-center">
            <h2>{{active.name}}</h2><i class="fas fa-2x marg-small" :class="active.icon"></i>
        </div>
        <div class="col-8">
            <div class="option-description">
                {{active.description}}
            </div>
            <div><b>Default Value: {{active.default}}</b></div>
            <i v-if="active.required === 'boolean'"
                class="fas fa-2x marg-small"
                :class="active.val ? 'fa-toggle-on c-green' : 'fa-toggle-off' "
                @click="modifyBooleanOption(active)"
            ></i>
            <span v-else-if="active.required==='string'">
                <strong class="pad-small">
                    {{active.val}}
                </strong>
                <i class="fas fa-edit fa-2x marg-small"
                    @click="modifyStringOption(active)"
                ></i>
            </span>
            <span v-else>
                <strong class="pad-small">{{active.val}} </strong>
                <i class="fas fa-edit fa-2x marg-small" @click="modifyNumberOption(active)"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BodyMain',
    props: ['active'],
    methods: {
        modifyBooleanOption(items) {
            items.val ? items.val = false : items.val = true
        },
        modifyStringOption(items) {
            const x = prompt('Type in a value to change to:')
            if (!x) return false
            // eslint-disable-next-line valid-typeof
            if (typeof x !== items.required) {
                alert(`looking for a ${items.required} value.`)
                return false
            }
            items.val = x
            return items.val
        },
        modifyNumberOption(items) {
            let x = prompt('Type in a value to change to:')
            if (items.required === 'number' && !isNaN(parseInt(x, 10))) {
                x = parseInt(x, 10);
            } else { x = null }
            // eslint-disable-next-line valid-typeof
            if (typeof x !== items.required) {
                alert(`looking for a ${items.required} value.`);
                return false;
            }
            if (!x) { return false }
            items.val = x
            return items.val
        },
    }
}
</script>

<style lang="sass">
.option-description
    white-space:pre-line
    max-height: 150px
    overflow: auto
    margin-bottom: 1em
</style>
