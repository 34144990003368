<template>
    <div class="srs-logs ">
        <div class="d-flex log-column" style="flex-direction:column">
            <div @click="$emit('open-modal', items)" class="a-log d-flex book-card pad-big marg-small"
                v-for="(items, indx) in logsToShow" :key="indx + 'a'" v-tippy="{ placement: 'left' }"
                :content="`You answered with ${items.answer}`">
                <h2>{{ items.card }}</h2>
                <i class="log-icon" :class="determineIconStyle(items.passed)"></i>
            </div>
        </div>
        <!--USE FOR MODAL-->
        <!-- I have no idea what this is actually -->
        <div v-if="0 == 1" class="d-flex" style="flex-direction:column;">
            <div v-if="logs.length > 0" class="book-card" v-for="(items, indx) in logs.slice(-3, 3)" :key="indx + 'b'">
                <div>
                    <span class="badge"
                        :class="items.passed === 'wrong' ? 'bg-danger' : items.passed == 'success' ? 'bg-success' : 'bg-warning'">
                        {{ `question ${indx}` }}
                    </span>
                    <span class="badge bg-info">
                        {{ `attmpt ${items.attemptNum}` }}
                    </span>
                </div>
                <h2>{{ items.card }}</h2>
                <div>
                    <p>{{ `Answered ${items.answer}` }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/*
filter:brightness(${[1,1.5,2][indx]});
            z-index:${[3,2,1][indx]};
            transform:translate(0, ${['0', '-50%', '-125%'][indx]});
            position:relative
            */
export default {
    name: 'Logs',
    props: [
        'logs'
    ],
    computed: {
        logsToShow() {
            const { logs } = this
            return logs.slice(-3).reverse()
        }
    },
    methods: {
        determineIconStyle(val) {
            switch (val) {
                case 'success':
                    return 'fas fa-check c-green'
                case 'wrong':
                    return 'fas fa-times c-red'
                case 'up':
                    return 'fas fa-angle-double-up c-green'
                case 'down':
                    return 'fas fa-angle-double-down c-red'
                case 'undo':
                    return 'fas fa-redo'
                case 'try again':
                    return 'fas fa-question'
                default:
                    return 'fas fa-question'
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.container-relative
    position: relative
    text-align: center
    .srs-logs
        /*potential bug*/
        position: absolute
        justify-content: flex-end
        opacity: 0
        @media (min-width: 768px)
            opacity: 1
body
    &.dark
        .srs-logs
            > div
                > div
                    background-color: #2a2a2a
.log-column
    min-width: 10em
    @media screen and (max-width: 768px)
        min-width: 7.5em
.a-log.d-flex
    justify-content: space-between
    align-items: center
    margin: 0
    z-index: 2
    font-size: 1rem
    i
        font-size: 1rem
</style>
