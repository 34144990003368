<template>
    <div class="modal-header modal-options-header">
        <span
            v-for="(items, indx) in tabs"
            :key="tabs+indx"
            class="badge clickable"
            :class="current === items ? 'bg-success' : 'bg-jet'"
            @click="switchTab(items)"
        >{{items}}</span>
        <span class="badge clickable bg-jet" @click="resetUserOptions()">Reset Options to Default</span>
    </div>
</template>

<script>
export default {
    name: 'ModalOptionsHeader',
    props: ['current'],
    methods: {
        switchTab(val) {
            this.$emit('switch-tab', val)
        },
        resetUserOptions() {
            const confirm = window.confirm('Would you like to reset all your options to the default?')
            if (!confirm) return
            const confirm2 = window.confirm('Are you sure?')
            if (!confirm2) return
            this.$emit('reset-options')
        }
    },
    data() {
        return {
            tabs: ['UI', 'behavior']
        }
    }
}
</script>

<style lang="sass" scoped>
.modal-header
    justify-content: flex-start
    font-size: 1.4rem
.badge
    text-transform: capitalize
    min-width: 4em
</style>
