<template>
<div slot="header-top" v-if="flags.post.postQueue" class="modal-header d-block">
    <h2 class="pad-small">Session complete
        <i class="far fa-question-circle" v-tippy content="Based on your answer logs, here are some items you struggled with."></i>
    </h2>
    <div class="progress container-relative" style="font-size: 50%; height: 1em">
        <div class="progress-bar bg-info" role="progressbar"
            :style="`width: ${(flags.post.postIndex + 1) / flags.post.postQueue.length * 100}%`"
        ></div>
        <span data-v-39fafa17="" class="pos-center">{{`${flags.post.postIndex + 1} / ${flags.post.postQueue.length}`}}</span>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
