<template>
    <div>
        <Notes
            v-if="showingNotes"
            :active="active"
            :flags="flags"
        />
        <!-- examples sentences -->
        <Corpus
            v-if="active.card_type !== 'k' && flags.question.status"
            :word="active.word"
            :examples="examples.filter( i=> i.val == active.word || active.entries.map(i=>i.reading).includes(i.val) )"
            class="corpus align-left col-12 col-md-10 col-lg-7 mx-auto"
            style="border-top: 1px solid #dee2e6;"
            :scoring="'auto'"
        />
        <!-- reference words -->
        <div
            class="col-12 col-lg-8 mx-auto"
            v-if="showingReference"
        >
            <div class="d-flex marg-big flex-wrap" v-if="reference.length > 0" style="justify-content: space-around;">
                <Reference
                    class="reference-word pad-small"
                    v-for="(refs, indx) in reference.filter( i=>i.cardId == active.id)"
                    :key="indx+'f'"
                    :item="refs"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Corpus from '../../Shared/Corpus.vue'
import Notes from './Notes.vue'
import Reference from './Reference.vue'

export default {
    name: 'Auxilliary',
    props: [
        'reference',
        'examples',
        'active',
        'flags',
        'options'
    ],
    components: {
        Notes,
        Reference,
        Corpus
    },
    computed: {
        showingNotes() {
            if ('showNotesBeforeInput' in this.options && this.options.showNotesBeforeInput.val === true) return true
            console.log('opts', this.options.showNotesBeforeInput)
            if (
                'notes' in this.active
                && this.active.notes !== ''
                && this.flags.question.status
            ) return true
            return false
        },
        showingReference() {
            if ('showReferenceWordsBeforeInput' in this.options && this.options.showReferenceWordsBeforeInput.val === false) return false
            return true
        }
    }
}
</script>

<style lang="scss">
.reference-word {
    flex: 1 0 300px
}
@media (min-width: 720px) {
    .reference-word {
        flex: 1 0 33%
    }
}
</style>
