<template>
    <div class="w-100">

        <div class="w-100"></div>

        <div class="i-group shaded w-100 mx-auto">
            <div class="i-group-opt">
                <span v-if="'word' in active" class="badge" :class="instructionBadge" v-tippy :content="instructionText"
                    v-html="flags.question.type == 'reading' ? 'reading' : $chosenReadingWithPronunciation">
                    <i v-if="'word' in active && $splitReadings.length < active.entries.length"
                        class="far fa-question-circle">
                    </i>
                </span>
            </div>
            <input id="answer" maxlength="60" autocomplete="off" autofocus :class="$inputFeedback" class="answer-inset"
                @change="fixWanakana()" @oninput="fixWanakana()" @keydown="handleInput($event)"
                @keyup.enter="enterFlowControl()" :placeholder="flags.question.type === 'reading' ? 'はつおん' : 'Meaning'" />
            <div class="clickable" @click="[fixWanakana(), enterFlowControl()]" v-tippy content="Submit/Continue">
                <i class="fas fa-chevron-circle-right"></i>
            </div>
        </div>
        <div v-if="flags.question.status" class="d-lg-none i-group shaded w-75 mx-auto marg-small" style="height: 2em">
            <div class="align-center even-spacing clickable" :class="flags.question.type !== 'reading' && flags.question.status === 'wrong'
                ? 'clickable'
                : 'o-50'
                " v-tippy content="Add synonym" @click="simulateAddSynonym">
                <i class="fas fa-plus"></i>
            </div>
            <div class="align-center even-spacing clickable" v-tippy content="Open editor" @click="simulateOpenModal">
                <i class="fas fa-expand"></i>
            </div>
            <div class="align-center even-spacing clickable" v-if="flags.question.status === 'wrong'" v-tippy
                content="Undo answer" @click="simulateUndoAnswer">
                <i class="fas fa-undo"></i>
            </div>
            <div class="align-center even-spacing clickable" v-if="flags.question.status !== 'wrong'" v-tippy
                content="Mark incorrect" @click="simulateMarkIncorrect">
                <i class="fas fa-times"></i>
            </div>
        </div>
    </div>
</template>

<script>
import {
    toHiragana, toKatakana
} from 'wanakana'
import C from '@/assets/common'

const {
    SendUserAlert
} = C

const wanakana = {
    toHiragana, toKatakana
}
/*
<!--timed question timer-->
    <div v-if="options.timedQuestions"
        class="progress w-75 mx-auto container-relative"
        style="height:5px;"
    >
        <div class="progress-bar bg-success"
            id="question-timer"
            role="progressbar"
        >
        </div>
    </div>
*/

export default {
    name: 'AnswerFld',
    created() {
        console.log('%cCreated AnswerFld', window.ConsoleStyles.createdComponent, this)
    },
    methods: {
        fixWanakana() {
            /* so wanakana breaks because it changes the field to hiragana AFTERE the handleAnswer event fires off instead of before
            The solution is just to manually assign what wanikani would it before handleAnswer fires
            This needs to be assigned to a onChange event listener before handleAnswer() */
            // let using = this.answerFld
            const using = this.getAnswerNode().value
            console.log('running fix wanakana')
            if (this.flags.question.type === 'reading') {
                if (using.slice(-2) === 'nn') this.getAnswerNode().value = using.substring(0, using.length - 1)
                this.getAnswerNode().value = wanakana.toHiragana(using.replace(/nn/g, 'ん'))
                // resync
                this.answerFld = this.getAnswerNode().value.trim()
            }
        },
        handleInput(e) {
            //  Handles inputs for when the question is considered ANSWERED already
            //  blocks further changes to input while in this condition while handling special commands
            const { flags } = this
            const element = this.getAnswerNode()
            // fake v-model (since it bugs with wanakana on firefox)
            this.answerFld = this.getAnswerNode().value
            //   stop tying if the question is 'finished'
            if (this.flags.question.status) e.preventDefault()
            //  if (!this.typable || !this.flags.question.status) return
            if (!this.flags.question.status) return
            const refocusAnswerField = () => {
                console.log('should refocus anser field')
                const answerFieldNode = document.getElementById('answer')
                if (answerFieldNode) answerFieldNode.focus()
            }

            if (this.flags.question.status === 'wrong') {
                if (e.which === 49) { //    this is 1 on the keyboard
                    this.simulateAddSynonym()
                    window.setTimeout(refocusAnswerField, 250)
                }
                if (e.which === 8 && flags.question.status === 'wrong') { // backspace
                    this.simulateUndoAnswer()
                    window.setTimeout(refocusAnswerField, 250)
                }
            }
            if (this.flags.question.status !== 'wrong') {
                if (e.which === 50) { //    this is 2
                    this.simulateMarkIncorrect()
                    window.setTimeout(refocusAnswerField, 250)
                }
            }
            if (e.which === 32) { //    space
                this.simulateOpenModal()
                window.setTimeout(refocusAnswerField, 250)
            }
        },
        enterFlowControl() {
            const { typable, flags } = this
            if (typable && flags.question.status) {
                this.$emit('next-question')
                this.activeQuestionTimer()
                return true
            }
            if (typable) return this.handleAnswer()
            console.log('Waiting for cooldown')
            return false
        },
        simulateAddSynonym() {
            if (this.flags.question.type !== 'reading') {
                this.$emit('add-synonym', {
                    active: this.active,
                    answer: this.answerFld
                })
            }
        },
        simulateOpenModal() {
            this.$emit('open-modal')
        },
        simulateUndoAnswer() {
            if (this.flags.question.status === 'wrong') this.$emit('undo-answer')
        },
        simulateMarkIncorrect() {
            //  go into item and mark as wrong
            if (this.flags.question.status !== 'wrong') this.$emit('mark-incorrect')
        },
        activeQuestionTimer() {
            // sets up a timer if the timerType options is set to 3;xxx
            //  this.flags.wrapup would only be an object if it's set as stated above
            //  else it would be a number (from being set = to a setTimeout) or something else
            if (typeof this.flags.wrapup !== 'object') { return false }
            const { wrapup } = this.flags
            const timerStyle3 = () => {
                console.log('timer style 3 running')
                const { flags } = this
                const answ = this.getAnswerNode().value
                if (answ === "" || !answ) {
                    this.getAnswerNode().value = '!'
                    SendUserAlert(`Timer ran out.`, 'alert-warning')
                }
                // this is the same as what would happen if you pressed enter
                // literally copied and pasted from the html event
                // without the this.activeQuesitonTimer() part
                this.fixWanakana()
                if (this.typable) return this.handleAnswer()
                return false
            }

            wrapup.timerId = setTimeout(timerStyle3, wrapup.time)
            return true
        },
        checkReadingAnswer(answer, item) {
            //  const get generally acceptable answers
            const acceptable = item.entries.map((entry) => entry.reading)
            //  get truly acceptable answers (reading toggled on)
            const trulyAcceptable = item.readings.split(',').map((i) => i.trim())
            //  check if list of answers has a match in either hiragana or katakana
            const isAMatch = (arr) => arr.find((read) => {
                // quirk in wanakana changes . to 。 so change that back before checking for kun yomi readings (which are formatted as XX.XX)
                const kunFixer = (str) => str.replace('。', '.').replace(/\.(.*?)$/g, '')
                const status = kunFixer(wanakana.toHiragana(read)) === wanakana.toHiragana(answer) || wanakana.toKatakana(read) === wanakana.toKatakana(answer)
                return status
            })
            const generateWarning = () => {
                //  was correct but not an enabled reading
                const notEnabledmatch = isAMatch(acceptable)
                if (notEnabledmatch) return `"<b>${notEnabledmatch}</b>" is valid, but not marked as acceptable.<br>Additional readings can be enabled by editing the word in lessons page (via searches) or during the review (via logs or the end-of-review screen)`
                //  check if it matches any reference words (if option is enabled)
                const referenceReadingWarningsEnabled = this.options.referenceReadingWarnings.val === true
                if (referenceReadingWarningsEnabled) {
                    // get readings from all reference words that are used with your current item
                    const arr = this.reference.filter((i) => i.cardId === item.id)
                    const found = arr.find((i) => i.entries.find((o) => o.reading === answer))
                    if (found) { return `Did you mix this word up for "${found.word}"?` }
                }
                return false
            }
            //  main
            const passed = isAMatch(trulyAcceptable)
            const warning = !passed ? generateWarning() : false
            return { answer, passed, warning }
        },
        checkDefinitionAnswer(answer, item) {
            const acceptable = [];
            const unacceptable = [];
            const leven = this.levenshteinDistance;
            const { leeway } = this.flags.question
            item.entries.forEach((entry) => {
                entry.subentries.forEach((subentry) => {
                    subentry.definition.forEach((defEntry) => {
                        /* push into unacceptable if it doesnt belong to the entry you chose */
                        if (item.readings === '') { // if word has no readings (reading part is skipped)
                            acceptable.push(defEntry.trim())
                        } else {
                            entry.reading !== item.status.chosenReading
                                ? unacceptable.push(defEntry.trim().toLowerCase())
                                : acceptable.push(defEntry.trim().toLowerCase())
                            console.log('acceptable', acceptable, 'unacc', unacceptable)
                        }
                    })
                })
            })
            // check if answerable
            if (acceptable.length < 1) SendUserAlert('No acceptable answers found. Add a synonym for this question to be solveable. Hint: Press 1 to add current answer or space to edit the item.', 'alert-danger')
            // use levenshtein to find if the difference between actual answer and possible answers are less than leeway
            let match = acceptable.find(
                (def) => {
                    const distAnswer = leven(answer, def)
                    const distAnswerWithRegex = leven(answer, def.replace(/\((.*?)\)/g, '').trim())
                    return Math.min(distAnswer, distAnswerWithRegex) <= leeway(answer.length)
                }
            )
            // if no match then check synonyms as well
            if (!match) {
                match = item.synonyms.split(';').find(
                    (syn) => {
                        const distAnswer = leven(answer, syn.toLowerCase())
                        const distAnswerWithRegex = leven(answer, syn.replace(/\((.*?)\)/g, '').trim().toLowerCase())
                        return Math.min(distAnswer, distAnswerWithRegex) <= leeway(answer.length)
                    }
                )
            }

            const warning = () => {
                //  return !(acceptable.includes(answer) || item.synonyms.split(',').includes(answer)) && unacceptable.includes(answer)
                /* if answer was a reading but it wasn't toggled on */
                if (acceptable.includes(answer) && !item.readings.split(',').includes(answer)) {
                    return "That reading is valid, but not marked as acceptable.\nAdditional readings can be enabled by editing the word in lessons page (via searches) or during the review (via logs or the end-of-review screen)"
                }
                /* if answer was wrong and it was a reference word */
                if (!match && this.options.referenceReadingWarnings.val === true) {
                    // get readings from all reference words that are used with your current item
                    const arr = this.reference.filter((i) => i.cardId === item.id)
                    const defMap = []
                    arr.forEach((refItems) => {
                        refItems.entries.forEach((entry) => {
                            entry.subentries.forEach((subentry) => {
                                subentry.definition.forEach((defEntry) => {
                                    defMap.push({ word: refItems.word, defEntry })
                                })
                            })
                        })
                    })

                    const found = defMap.find((i) => i.defEntry === answer)
                    if (found) return `Did you mix this word up for ${found.word}?`
                }
                return false
            }
            return {
                answer,
                passed: match,
                warning: warning()
            }
        },
        handleAnswer() {
            const { answerFld, active } = this;
            const { question } = this.flags
            let result
            if (!question || !question.type || !answerFld) {
                console.log('question type not set')
                return
            } if (answerFld.match(/[a-zA-Z]/) && question.type === 'reading') {
                SendUserAlert('Mixed answer detected.', 'alert-info')
                return
            }
            //  if (this.options.timedQuestions) this.offTimedQuestions()
            if (question.type === 'reading') {
                result = this.checkReadingAnswer(answerFld.trim().toLowerCase(), active)
            } else {
                result = this.checkDefinitionAnswer(answerFld.trim().toLowerCase(), active)
            }
            question.type === 'reading'
                ? result = this.checkReadingAnswer(answerFld.trim().toLowerCase(), active)
                : result = this.checkDefinitionAnswer(answerFld.trim().toLowerCase(), active)

            const modifyResultsReading = () => {
                if (result.passed) {
                    active.status.chosenReading = result.passed
                    question.status = 'success'
                    return
                }
                if (result.warning) {
                    question.status = 'try again'
                    if (!this.flags.firstTryAgain) SendUserAlert(result.warning, 'alert-warning')
                    return
                }
                if (!result.warning) {
                    active.status.readingWrong = true
                    question.status = 'wrong'
                }
            }
            const modifyResultsDefinition = () => {
                if (result.passed) {
                    active.status.finished = true
                    question.status = 'success'
                    return
                }
                if (result.warning) {
                    question.status = 'try again'
                    if (!this.flags.firstTryAgain) SendUserAlert(result.warning, 'alert-warning')
                    return
                }
                active.status.definitionWrong = true
                question.status = 'wrong'
            }

            question.type === 'reading'
                ? modifyResultsReading()
                : modifyResultsDefinition()
            this.typable = false
            result.passed || this.flags.debug
                ? this.lockControls(100)
                : this.lockControls(500)
            console.log('CLEARTIMERANSWER CURRENTLY DISABLED. PLEASE REENABLE')
            //  this.clearTimerAnswer()
            // lightning mode
            this.activateLightning(result.passed, 120)
            console.log('handleAnswer result was', result)
        },
        activateLightning(passed, time) {
            if (!passed || !('lightningMode' in this.options && this.options.lightningMode.val)) return false
            const getNextQuestion = () => {
                if (this.flags.question.status) this.enterFlowControl()
                this.answerFld = "" //  normally done in parent's reviewOver() but it doesnt update fast enough to prevent an enter + this func
            }
            if (passed) {
                return window.setTimeout(getNextQuestion, time)
            }
            return false
        },
        lockControls(len = 100) {
            console.log('running lock Controls', len)
            const lock = () => {
                console.log('controls unlocked')
                this.typable = true
            }
            return window.setTimeout(lock, len)
        },
        getAnswerNode() {
            return document.getElementById('answer')
        }
    },
    data() {
        return {
            answerFld: '',
            answerFldNode: 'answer',
            wanakana,
            typable: true,
        }
    },
    computed: {
        instructionBadge() {
            const { flags } = this
            return flags.question.type === 'reading' ? 'bg-jet c-white' : 'bg-jet c-white'
        },
        instructionText() {
            const { flags } = this
            const { $splitReadings, active, $inactiveWordReadings } = this
            if (flags.question.type === 'reading') return `The following readings are disabled: ${$splitReadings.length < active.entries.length ? $inactiveWordReadings.toString() : 'none'}`
            return 'Must answer with a definition for the chosen reading OR a synonym that you manually added'
        },
        $inputFeedback() {
            if (['try again'].includes(this.flags.question.status)) return 'spelling'
            if (this.flags.question.status === 'wrong') return 'wrong'
            if (this.flags.question.status === 'success') return 'success'
            return ' '
        },
    },
    props: [
        'active',
        'flags',
        'question',
        'options',
        'reference',
        'levenshteinDistance',
        '$chosenReadingWithPronunciation',
        '$inactiveWordReadings',
        '$splitReadings'
    ]
}
</script>

<style lang="sass" scoped>
#answer
    line-height: 1.8
    text-align: center
    border-radius: 3px
    font-size: 1.5rem
    text-indent: 33%
    &.success
        color: white
        background-color: #00ce1b
    &.spelling
        background-color: #ffdaa6
    &.wrong
        color: white
        background-color: #ce0000
@media only screen and (min-width: 576px)
    #answer
        text-indent: 0
</style>
