<template>
    <div>
        <!-- all definitions (when question is answered) -->
        <span v-if="flags.question.type == 'definition' && active.status.chosenReading">
            <!--definitions-->
            <div>
                <span class="badge" :class="cardIsVocab ? 'bg-purple' : 'bg-danger'"
                    v-html="$chosenReadingWithPronunciation"></span>
                <span v-for="(items, indx) in $relevantAnswerBadges" :key="indx + 'd'" style="margin-right:.25em;">
                    <span v-if="cardIsVocab" class="badge bg-info" v-tippy :content="items.pos">
                        <i class="far fa-question-circle"></i>
                    </span>
                    <span class="badge" :class="determineDefinitionBadgeColor(defs)"
                        v-for="(defs, indx) in items.definition.slice(0, 3)" :key="indx + 'e'">
                        {{ defs }}
                    </span>
                    <span v-if="items.definition.length > 3" class="badge bg-secondary" v-tippy
                        :content="items.definition.join(', ')">
                        <i class="fa fa-ellipsis-h"></i>
                    </span>
                </span>
            </div>
            <!--synonyms-->
            <div v-if="$hasSynonyms === true">
                <span class="badge bg-info">Synonyms</span>
                <span style="margin-right:.25em;">
                    <span class="badge" :class="determineDefinitionBadgeColor(defs)"
                        v-for="(defs, indx) in active.synonyms.split(';').slice(0, 3)" :key="indx + 'z'">
                        {{ defs }}
                    </span>
                    <span v-if="active.synonyms.split(';').length > 3" class="badge bg-secondary" v-tippy
                        :content="active.synonyms">
                        <i class="fa fa-ellipsis-h"></i>
                    </span>
                </span>
            </div>
        </span>
        <!-- all readings (when question is answered) -->
        <span v-else-if="flags.question.type == 'reading'" class="badge"
            :class="entry == active.status.chosenReading ? 'bg-success' : 'bg-secondary'"
            v-for="(entry, indx) in $activeReadings" :key="indx + 'e'">{{ entry }}</span>
    </div>
</template>

<script>
export default {
    name: 'DefinitionsAndReadings',
    created() {
        console.log('%cCreated DefinitionsAndReadings', window.ConsoleStyles.createdComponent, this)
    },
    methods: {
        getAnswerFld() {
            return document.getElementById('answer').value
        },
        determineDefinitionBadgeColor(defs) {
            //  check if item is 'correct' (within leeway)
            const { levenshteinDistance, flags } = this
            const answerFld = this.getAnswerFld()
            const withoutRegex = levenshteinDistance(answerFld, defs.trim())
            const withRegex = levenshteinDistance(answerFld, defs.replace(/\((.*?)\)/g, '').trim())
            const correct = Math.min(withoutRegex, withRegex) <= flags.question.leeway(answerFld.length)
            return correct ? 'bg-success' : 'bg-secondary'
        }
    },
    computed: {
        cardIsVocab() {
            return this.active.card_type === 'v'
        },
        $relevantAnswerBadges() {
            const answerFld = this.getAnswerFld()
            const synArray = this.active.synonyms.split(',')
            const synCheck = synArray.filter(
                (i) => Math.min(
                    this.levenshteinDistance(answerFld, i),
                    this.levenshteinDistance(answerFld, i.replace(/\((.*?)\)/g, '').trim())
                ) <= this.flags.question.leeway(answerFld.length)
            )
            // eslint-disable-next-line no-param-reassign
            synCheck.forEach((i) => { i = `*${i}` })
            return synCheck.length > 0
                ? this.$defsForChosenReading.concat({ definition: synCheck, pos: 'synonyms' })
                : this.$defsForChosenReading
        },
        $defsForChosenReading() {
            if (!this.active.status.chosenReading) { return [] }
            const relevant = this.active.entries
                .filter((i) => i.reading === this.active.status.chosenReading)[0].subentries
            //  remove empty defs
            relevant.forEach((o) => {
                o.definition = o.definition.filter((defs) => defs)
            })
            return relevant
            // return this.active.entries.filter((i)=>i.reading==this.active.status.chosenReading)[0].definition
        },
        $hasSynonyms() {
            const { synonyms } = this.active
            if (!synonyms) return false
            if (synonyms.split(';')[0] === "") return false
            return true
        }
    },
    props: [
        '$chosenReadingWithPronunciation',
        '$activeReadings',
        'levenshteinDistance',
        'active',
        'flags'
    ]
}
</script>
