<template>
    <Modal
    :showing="showingModal"
    v-on:click="$emit('click-out')"
    position="stable"
    >
        <template v-slot:header>
            <div class="modal-header">
                <h2>Session complete</h2>
            </div>
        </template>
        <template v-slot:body>
            <div class="modal-body">
                <h2>Accuracy: <span>{{`${accuracy}%`}}</span></h2>
                <h2>{{`Reviews Completed: ${totalReviews}`}}</h2>
            </div>
        </template>
        <template v-slot:footer>
            <div class="modal-footer">
                <button type="button" class="btn bg-success clickable" @click="$emit('leave')">lessons page</button>
            </div>
        </template>
    </Modal>
</template>

<script>
import {
    Modal,
} from '@/components/Shared/index'

export default {
    emits: [
        'leave',
        'click-out',
    ],
    props: [
        // from ui.progress[x]
        'correct',
        'incorrect',
        'max',
        'showingModal',
    ],
    components: {
        Modal,
    },
    computed: {
        accuracy() {
            return (this.correct / this.max).toFixed(2) * 100
        },
        totalReviews() {
            return this.correct + this.incorrect
        }
    }
}
</script>

<style>

</style>
