export default {
    quizzes: {
        header: 'Quizzes',
        body: 'If you answer a question right during a quiz it will be added to your spaced repetition rotation. The word will appear more or less frequently depending on how accurately you tend to answer it.<br>If you get a question wrong it will be returned to the lessons page where you can try again.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    answering: {
        header: 'Reviewing',
        body: 'If a question says \'reading\' to the left of the input, then type in the word or kanji\'s reading. Otherwise, type one of the meanings (not all).<br>If the word has multiple readings, selected reading\'s definitions are valid answers. On reading questions, input will be automatically converted to hiragana or katakana, depending on the what\'s relevant.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    logs: {
        header: 'Logs',
        body: 'After answering a word, you can press space to edit the card. Alternatively, you can use the logs on the right side of the screen (desktop only).',
    },
    shortcuts: {
        header: 'Keyboard Shortcuts',
        body: '(Enter): Submit answer/next question<br>(1): Add as synonym<br>(2): Manually mark as incorrect<br>(Backspace): Undo wrong answer<br>(Space): Edit card',
    }
}
